@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: var(--color-default);
    font-family: 'CustomFont2', sans-serif;
    font-optical-sizing: auto;
    border: none;
    outline: none;
}

body {
    background-color: #FBFAF5;
}

@font-face {
    font-family: 'CustomFont';
    src: url('/src/Assets/Font/CanelaText-Bold-Trial.otf');
}

@font-face {
    font-family: 'CustomFont2';
    src: url('/src/Assets/Font/CanelaText-Light-Trial.otf');
}

.mt100 {
    margin-top: 100px;
}

.fb {
    font-family: var(--big-font);
}

:root {
    --color-default: #151411;
    --color-second: #DCD7D0;
    --color-second-light: #0000000f;
    --color-light: #F7F6F3;
    --color-white: #FFFFFF;
    --big-font: 'CustomFont', sans-serif;
    --small-font: 'CustomFont2', sans-serif;
}

.dark-button {
    border: 1px solid var(--color-default);
    padding: 8px 15px;
    background-color: var(--color-default);
    border-radius: 7px;
    font-size: 15px;
    color: var(--color-white);

    &:hover {
        border: 2px solid var(--color-default);
    }
}

.line-button {
    border: 1px solid var(--color-default);
    padding: 8px 15px;
    background-color: transparent;
    border-radius: 7px;
    font-size: 15px;
    color: var(--color-default);

    &:hover {
        background-color: var(--color-default);
        color: var(--color-white);
    }
}

.navbar-toggler {
    border: none !important;
}

.navbar {
    background-color: var(--color-white);
    padding: 15px 0;

    .logo {
        img {
            width: 150px;
        }
    }

    .nav-link {
        font-size: 15px;
    }

    .navbar-nav {
        background: var(--color-light);
        padding: 0 20px;
        border-radius: 10px;
        position: relative;

        li {
            margin: 5px 10px;

            &:hover {
                background-color: var(--color-white);
                border-radius: 5px;

                .nav-link {
                    font-family: var(--big-font) !important;
                }
            }
        }
    }

    .nav-right {
        display: flex;
        align-items: center;

        li {
            list-style: none;
            margin: 0 15px;

            .globe {
                width: 20px;
            }

            .off {
                width: 25px;
            }
        }
    }
}

.modal-content {
    border: none !important;
    background-color: var(--color-white);
    margin-top: 80px;

    .drop {
        display: flex;
        align-items: center;

        .image {
            img {
                width: 70px;
            }
        }

        h4 {
            font-family: var(--big-font);
            font-size: 15px;
            margin: 0;

            img {
                width: 12px;
                margin-bottom: 3px;
            }
        }

        p {
            margin: 0;
            font-size: 13px;
        }
    }
}

.offcanvas {
    .btn-close {
        color: var(--color-default);
    }

    .close {
        display: flex;
        justify-content: flex-end;
        cursor: pointer;

        img {
            width: 25px !important;
        }
    }

    .links ul {
        padding: 0;

        li {
            list-style: none;
            border-bottom: 2px solid var(--color-default);
            padding: 20px 0;
            text-align: right;
            cursor: pointer;

            &:hover {
                border-bottom: 3px solid var(--color-default);
            }
        }

        .b-none {
            border: none !important;
        }
    }

    .social ul {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        list-style: none;

        img {
            width: 20px;
        }
    }
}

.banPadding {
    padding: 100px 70px;
}

.Banner {
    .img {
        background-image: url(../Assets/Images/5460d18f1730a5c9b5d4e97887482a30.jpeg);
        height: 500px;
        background-position: center;
        background-size: cover;
    }

    .text {
        background-color: var(--color-second);
        height: 500px;

        h1 {
            font-family: var(--big-font);
            font-size: 45px;
        }

        p {
            font-size: 20px;
            margin: 20px 0;
        }
    }
}

.BuildingSection {
    .img {
        background-image: url(../Assets/Images/Rectangle\ 34624626.png);
        height: 500px;
        background-position: center;
        background-size: cover;
    }

    .img2 {
        background-image: url(../Assets/Images/Rectangle\ 34624626\ \(1\).png);
        height: 500px;
        background-position: center;
        background-size: cover;
    }

    .text {
        background-color: var(--color-second);
        height: 500px;

        h1 {
            font-family: var(--big-font);
            font-size: 45px;
        }

        p {
            font-size: 20px;
            margin: 20px 0;
        }
    }
}

.HowSection {
    .one {
        .img2 {
            width: 60px;
            margin-left: 70px;
        }

        .img3 {
            width: 100%;
        }

        p {
            font-size: 20px;
            margin: 20px 0;
        }
    }

    .two {
        min-height: 400px;
        background-image: url(../Assets/Images/Hero\ image.png);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .down p b {
        font-family: var(--big-font);
    }
}

.Contact {
    background-color: var(--color-white);

    .email {
        display: flex;
        align-items: center;

        img {
            width: 60px;
        }

        small {
            margin: 0;
            font-size: 12px;
            color: grey;
        }

        p {
            margin: 0;
        }
    }

    .connect {
        img {
            width: 30px;
        }

        div {
            display: flex;
            align-items: center;
        }

        ul {
            padding: 0;
            display: flex;
            align-items: center;

            li {
                list-style: none;
                margin: 5px;
                background-color: var(--color-second);
                border-radius: 5px;
                cursor: pointer;
                padding: 4px 8px;

                svg {
                    height: 20px;
                    width: 20px;
                }

                &:hover {
                    background-color: var(--color-white) !important;
                }
            }
        }
    }

    .form {
        box-shadow: 0px 16px 72px 0px #061C3D14;
        background-color: var(--color-white);
        border-radius: 20px;

        h3,
        b,
        p,
        small,
        span,
        label {
            font-family: "Inter", sans-serif !important;
        }

        label {
            font-size: 14px;
        }

        input {
            height: 45px;
        }

        small {
            span {
                color: blue;
            }
        }

        font-style: normal;
        padding: 30px;

        button {
            img {
                width: 20px;
                margin-left: 10px;
            }
        }
    }
}

.Achievement {
    background: #000;
    padding: 30px 0;
    padding-bottom: 10px;

    h1,
    p {
        color: var(--color-white);
    }

    p {
        font-size: 20px;
    }

    h1 {
        font-family: var(--big-font);
    }
}

.Clients {
    ul {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding: 0;

        li {
            list-style: none;
            margin: 15px;
            background: var(--color-white);
            padding: 10px;
            border-radius: 10px;

            img {
                width: 120px;
            }
        }
    }
}

.Review {
    background-color: var(--color-second);
    padding: 50px 0;

    .swiper {
        width: 100%;
    }

    .swiper-slide {
        background-position: center;
        background-size: cover;
        width: 500px;
        height: 300px;
        margin: 0 20px;

        img {
            display: block;
            width: 100%;
        }
    }

    .swipeCard {
        display: flex;
        align-items: center;
        background-color: var(--color-white);
        padding: 15px;
        border-radius: 10px;

        .text {
            margin-left: 10px;
            text-align: left;

            p {
                font-size: 11px;
            }

            span {
                font-size: 10px;
            }

            h4 {
                font-size: 14px;
                font-family: var(--big-font);
                margin: 0;
            }

        }

        .cl {
            img {
                width: 150px;
            }
        }

        .img {
            img {
                width: 20px;
                ;
            }

            display: flex;
            justify-content: end;
            margin-right: 20px;
        }
    }
}

.Footer {
    background-color: var(--color-default);
    padding: 50px 0;
    .list {
        display: flex;
        flex-wrap: wrap;
        ul {
            li {
                list-style: none;
                color: grey;
                font-family: "Inter", sans-serif;
                font-size: 14px;
                margin: 5px 0;
            }
            .big{
                font-weight: 800 !important; 
                font-family: var(--big-font);
                color: #D0DAF5;
                font-size: 17px;
            }
        }
    }
    .big{
        font-weight: 800 !important; 
        font-family: var(--big-font);
        color: #D0DAF5;
        font-size: 17px;
    }
    .email{
        display: flex;
        align-items: center;
        background-color: var(--color-light);
        border: 2px solid var(--color-light);
        input{
            background-color: var(--color-default);
            height: 45px;
            padding: 0 10px;
            width: 85%;
            margin-right: 5px;
        }
        svg{
            width: 25px ;
            height: 25px;
        }
    }
    .IMG{
        img{
            width: 85%;
        }
    }
    hr{
        color: var(--color-light);
    }
    .down{
        display: flex;
        align-items: center;
        justify-content: space-between;
        p{
            color: grey;
            font-family: "Inter", sans-serif;
            font-size: 13px;
        }
        ul{
            display: flex;
            align-items: center;
            padding: 0;
            li{
                list-style: none;
                color: grey;
                font-family: "Inter", sans-serif;
                font-size: 13px;
                margin: 0 10px;
            }
        }
    }
}

.swiper-pagination-bullet-active {
    background-color: #000 !important;
}

@media only screen and (max-width: 500px) {
    .banPadding {
        padding: 70px 40px;
    }

    .Review {
        background-color: var(--color-second);
        padding: 50px 0;

        .swiper {
            width: 100%;
        }

        .swiper-slide {
            width: 350px;
            height: 400px;
            margin: 0 80px;
        }

        .swipeCard {
            display: flex;
            align-items: center;
            flex-direction: column;

            .img {
                img {
                    width: 20px;
                    display: none;
                }
            }
        }
    }
}