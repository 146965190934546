@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: var(--color-default);
  font-family: "CustomFont2", sans-serif;
  font-optical-sizing: auto;
  border: none;
  outline: none;
}

body {
  background-color: #FBFAF5;
}

@font-face {
  font-family: "CustomFont";
  src: url("/src/Assets/Font/CanelaText-Bold-Trial.otf");
}
@font-face {
  font-family: "CustomFont2";
  src: url("/src/Assets/Font/CanelaText-Light-Trial.otf");
}
.mt100 {
  margin-top: 100px;
}

.fb {
  font-family: var(--big-font);
}

:root {
  --color-default: #151411;
  --color-second: #DCD7D0;
  --color-second-light: #0000000f;
  --color-light: #F7F6F3;
  --color-white: #FFFFFF;
  --big-font: "CustomFont", sans-serif;
  --small-font: "CustomFont2", sans-serif;
}

.dark-button {
  border: 1px solid var(--color-default);
  padding: 8px 15px;
  background-color: var(--color-default);
  border-radius: 7px;
  font-size: 15px;
  color: var(--color-white);
}
.dark-button:hover {
  border: 2px solid var(--color-default);
}

.line-button {
  border: 1px solid var(--color-default);
  padding: 8px 15px;
  background-color: transparent;
  border-radius: 7px;
  font-size: 15px;
  color: var(--color-default);
}
.line-button:hover {
  background-color: var(--color-default);
  color: var(--color-white);
}

.navbar-toggler {
  border: none !important;
}

.navbar {
  background-color: var(--color-white);
  padding: 15px 0;
}
.navbar .logo img {
  width: 150px;
}
.navbar .nav-link {
  font-size: 15px;
}
.navbar .navbar-nav {
  background: var(--color-light);
  padding: 0 20px;
  border-radius: 10px;
  position: relative;
}
.navbar .navbar-nav li {
  margin: 5px 10px;
}
.navbar .navbar-nav li:hover {
  background-color: var(--color-white);
  border-radius: 5px;
}
.navbar .navbar-nav li:hover .nav-link {
  font-family: var(--big-font) !important;
}
.navbar .nav-right {
  display: flex;
  align-items: center;
}
.navbar .nav-right li {
  list-style: none;
  margin: 0 15px;
}
.navbar .nav-right li .globe {
  width: 20px;
}
.navbar .nav-right li .off {
  width: 25px;
}

.modal-content {
  border: none !important;
  background-color: var(--color-white);
  margin-top: 80px;
}
.modal-content .drop {
  display: flex;
  align-items: center;
}
.modal-content .drop .image img {
  width: 70px;
}
.modal-content .drop h4 {
  font-family: var(--big-font);
  font-size: 15px;
  margin: 0;
}
.modal-content .drop h4 img {
  width: 12px;
  margin-bottom: 3px;
}
.modal-content .drop p {
  margin: 0;
  font-size: 13px;
}

.offcanvas .btn-close {
  color: var(--color-default);
}
.offcanvas .close {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.offcanvas .close img {
  width: 25px !important;
}
.offcanvas .links ul {
  padding: 0;
}
.offcanvas .links ul li {
  list-style: none;
  border-bottom: 2px solid var(--color-default);
  padding: 20px 0;
  text-align: right;
  cursor: pointer;
}
.offcanvas .links ul li:hover {
  border-bottom: 3px solid var(--color-default);
}
.offcanvas .links ul .b-none {
  border: none !important;
}
.offcanvas .social ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  list-style: none;
}
.offcanvas .social ul img {
  width: 20px;
}

.banPadding {
  padding: 100px 70px;
}

.Banner .img {
  background-image: url(../Assets/Images/5460d18f1730a5c9b5d4e97887482a30.jpeg);
  height: 500px;
  background-position: center;
  background-size: cover;
}
.Banner .text {
  background-color: var(--color-second);
  height: 500px;
}
.Banner .text h1 {
  font-family: var(--big-font);
  font-size: 45px;
}
.Banner .text p {
  font-size: 20px;
  margin: 20px 0;
}

.BuildingSection .img {
  background-image: url(../Assets/Images/Rectangle\ 34624626.png);
  height: 500px;
  background-position: center;
  background-size: cover;
}
.BuildingSection .img2 {
  background-image: url(../Assets/Images/Rectangle\ 34624626\ \(1\).png);
  height: 500px;
  background-position: center;
  background-size: cover;
}
.BuildingSection .text {
  background-color: var(--color-second);
  height: 500px;
}
.BuildingSection .text h1 {
  font-family: var(--big-font);
  font-size: 45px;
}
.BuildingSection .text p {
  font-size: 20px;
  margin: 20px 0;
}

.HowSection .one .img2 {
  width: 60px;
  margin-left: 70px;
}
.HowSection .one .img3 {
  width: 100%;
}
.HowSection .one p {
  font-size: 20px;
  margin: 20px 0;
}
.HowSection .two {
  min-height: 400px;
  background-image: url(../Assets/Images/Hero\ image.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.HowSection .down p b {
  font-family: var(--big-font);
}

.Contact {
  background-color: var(--color-white);
}
.Contact .email {
  display: flex;
  align-items: center;
}
.Contact .email img {
  width: 60px;
}
.Contact .email small {
  margin: 0;
  font-size: 12px;
  color: grey;
}
.Contact .email p {
  margin: 0;
}
.Contact .connect img {
  width: 30px;
}
.Contact .connect div {
  display: flex;
  align-items: center;
}
.Contact .connect ul {
  padding: 0;
  display: flex;
  align-items: center;
}
.Contact .connect ul li {
  list-style: none;
  margin: 5px;
  background-color: var(--color-second);
  border-radius: 5px;
  cursor: pointer;
  padding: 4px 8px;
}
.Contact .connect ul li svg {
  height: 20px;
  width: 20px;
}
.Contact .connect ul li:hover {
  background-color: var(--color-white) !important;
}
.Contact .form {
  box-shadow: 0px 16px 72px 0px rgba(6, 28, 61, 0.0784313725);
  background-color: var(--color-white);
  border-radius: 20px;
  font-style: normal;
  padding: 30px;
}
.Contact .form h3,
.Contact .form b,
.Contact .form p,
.Contact .form small,
.Contact .form span,
.Contact .form label {
  font-family: "Inter", sans-serif !important;
}
.Contact .form label {
  font-size: 14px;
}
.Contact .form input {
  height: 45px;
}
.Contact .form small span {
  color: blue;
}
.Contact .form button img {
  width: 20px;
  margin-left: 10px;
}

.Achievement {
  background: #000;
  padding: 30px 0;
  padding-bottom: 10px;
}
.Achievement h1,
.Achievement p {
  color: var(--color-white);
}
.Achievement p {
  font-size: 20px;
}
.Achievement h1 {
  font-family: var(--big-font);
}

.Clients ul {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
}
.Clients ul li {
  list-style: none;
  margin: 15px;
  background: var(--color-white);
  padding: 10px;
  border-radius: 10px;
}
.Clients ul li img {
  width: 120px;
}

.Review {
  background-color: var(--color-second);
  padding: 50px 0;
}
.Review .swiper {
  width: 100%;
}
.Review .swiper-slide {
  background-position: center;
  background-size: cover;
  width: 500px;
  height: 300px;
  margin: 0 20px;
}
.Review .swiper-slide img {
  display: block;
  width: 100%;
}
.Review .swipeCard {
  display: flex;
  align-items: center;
  background-color: var(--color-white);
  padding: 15px;
  border-radius: 10px;
}
.Review .swipeCard .text {
  margin-left: 10px;
  text-align: left;
}
.Review .swipeCard .text p {
  font-size: 11px;
}
.Review .swipeCard .text span {
  font-size: 10px;
}
.Review .swipeCard .text h4 {
  font-size: 14px;
  font-family: var(--big-font);
  margin: 0;
}
.Review .swipeCard .cl img {
  width: 150px;
}
.Review .swipeCard .img {
  display: flex;
  justify-content: end;
  margin-right: 20px;
}
.Review .swipeCard .img img {
  width: 20px;
}

.Footer {
  background-color: var(--color-default);
  padding: 50px 0;
}
.Footer .list {
  display: flex;
  flex-wrap: wrap;
}
.Footer .list ul li {
  list-style: none;
  color: grey;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  margin: 5px 0;
}
.Footer .list ul .big {
  font-weight: 800 !important;
  font-family: var(--big-font);
  color: #D0DAF5;
  font-size: 17px;
}
.Footer .big {
  font-weight: 800 !important;
  font-family: var(--big-font);
  color: #D0DAF5;
  font-size: 17px;
}
.Footer .email {
  display: flex;
  align-items: center;
  background-color: var(--color-light);
  border: 2px solid var(--color-light);
}
.Footer .email input {
  background-color: var(--color-default);
  height: 45px;
  padding: 0 10px;
  width: 85%;
  margin-right: 5px;
}
.Footer .email svg {
  width: 25px;
  height: 25px;
}
.Footer .IMG img {
  width: 85%;
}
.Footer hr {
  color: var(--color-light);
}
.Footer .down {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Footer .down p {
  color: grey;
  font-family: "Inter", sans-serif;
  font-size: 13px;
}
.Footer .down ul {
  display: flex;
  align-items: center;
  padding: 0;
}
.Footer .down ul li {
  list-style: none;
  color: grey;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  margin: 0 10px;
}

.swiper-pagination-bullet-active {
  background-color: #000 !important;
}

@media only screen and (max-width: 500px) {
  .banPadding {
    padding: 70px 40px;
  }
  .Review {
    background-color: var(--color-second);
    padding: 50px 0;
  }
  .Review .swiper {
    width: 100%;
  }
  .Review .swiper-slide {
    width: 350px;
    height: 400px;
    margin: 0 80px;
  }
  .Review .swipeCard {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .Review .swipeCard .img img {
    width: 20px;
    display: none;
  }
}/*# sourceMappingURL=style.css.map */